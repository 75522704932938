* {
    box-sizing: border-box;
}

body {
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    margin: 0;
    height: 100vh;
}

#root {
    height: 100%;
}

a {
    color: #006983;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Helvetica Neue", Helvetica, sans-serif;
}

#container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

header, main, footer {
    display: flex;
}

main {
    flex-grow: 1;
}

footer {
    background-color: #2f2f2f;
    color: white;
    margin-top: auto;
}

footer a {
    color: white;
}

#header-content, #main-content, #footer-content {
    margin: 0px auto;
    display: flex;
    max-width: 1200px;
    width: 100%;
}

#header-content {
    flex-direction: row;
    padding: 12px;
    gap: 40px;
}

#header-content-left img {
    width: 140px;
}

#header-content-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

#header-content-right h1 {
    font-size: 18px;
    font-weight: 400;
}

#main-content {
    flex-direction: column;
    padding: 12px;
    gap: 12px;
    margin-bottom: 40px;
}

#footer-content {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
}

#footer-content-top {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

#form-content {
    background-color: #ececec;
    padding: 12px;
}

#form-content h3 {
    margin-top: 0;
}

#form {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.form-col {
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.form-item {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

#form select {
    padding: 4px 6px;
    width: 100%;
    max-width: 250px;
}

.form-item label:not(input[type="checkbox"] + label) {
    font-weight: bold;
    min-width: 80px;
}

#form select, #form input {
    cursor: pointer;
}
